import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`객체는 JavaScript라는 언어만이 가지고 있는 특징의 기초를 이루는 자료형으로, `}<strong parentName="p">{`많은 기능`}</strong>{`을 내장하고 있습니다.`}</p>
    <h2>{`객체 리터럴 (Object Literal)`}</h2>
    <p>{`객체는 한꺼번에 여러 값을 담을 수 있는 `}<strong parentName="p">{`통(container)`}</strong><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`과 같은 `}<strong parentName="p">{`자료구조(data structure)`}</strong>{`입니다. 객체 안에는 `}<strong parentName="p">{`이름-값 쌍(name-value pair)`}</strong>{`이 저장되는데, 이를 객체의 `}<strong parentName="p">{`속성(property)`}</strong>{`이라고 합니다.`}</p>
    <p>{`아래와 같이 객체 리터럴(object literal)을 이용해서 객체를 생성할 수 있습니다. 중괄호 안에 직접 이름-값 쌍을 적어주면 됩니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const person = {
  name: '윤아준', // 속성 이름 - 'name', 속성 값 - '윤아준'
  age: 19, // 속성 이름 - 'age', 속성 값 - 19
  'languages': ['Korean', 'English'], // 속성 이름 - 'languages', 속성 값 - 배열
  '한국 나이': 20 // 속성 이름 - '한국 나이', 속성 값 - 20
};
`}</code></pre>
    <p>{`위에서 `}<inlineCode parentName="p">{`person`}</inlineCode>{` 변수에 할당된 객체에는 네 개의 속성이 저장되었습니다. `}<inlineCode parentName="p">{`'languages'`}</inlineCode>{`와 `}<inlineCode parentName="p">{`'한국 나이'`}</inlineCode>{`와 같이 속성 이름 부분에 문자열을 써도 상관없습니다만, `}<inlineCode parentName="p">{`'한국 나이'`}</inlineCode>{`에 들어간 공백과 같이 `}<strong parentName="p">{`식별자에 허용되지 않는 문자가 들어간 속성 이름`}</strong>{`을 정의할 때는 `}<strong parentName="p">{`반드시 문자열 표기를 사용`}</strong>{`해야 합니다.`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <p>{`객체 리터럴을 이용해 속성을 지정할 때, 아래와 같이 이미 정의된 변수의 이름을 그대로 속성의 값으로 사용할 수도 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const name = '윤아준'

const person = {
  name: name,
  age: 19,
  // ...
};
`}</code></pre>
    <p>{`위 코드를 아래와 같이 줄여 쓸 수도 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const name = '윤아준'

const person = {
  name, // \`name: name\`과 똑같이 동작합니다.
  age: 19,
  // ...
};
`}</code></pre>
    <p>{`아래와 같이 대괄호를 사용해서 다른 변수에 저장된 문자열을 그대로 속성의 이름으로 쓰는 것도 가능합니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const propName = 'prop';

const obj = {
  [propName]: 1
};

obj.prop; // 1
`}</code></pre>
    <h2>{`점 표기법, 대괄호 표기법`}</h2>
    <p>{`아래와 같이 `}<strong parentName="p">{`속성 접근자(property accessor)`}</strong>{`를 이용해 이미 생성된 객체의 속성을 지정해줄 수도 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const person = {}; // 빈 객체

// 점 표기법 (Dot notation)
person.name = '윤아준';
person.age = 19;
person.languages = ['Korean', 'English'];
`}</code></pre>
    <p>{`위에서는 객체 리터럴을 이용해 빈 객체를 생성해 준 뒤, `}<strong parentName="p">{`점 표기법`}</strong>{`을 통해 속성을 갱신해주었습니다. 그러나, JavaScript에서 식별자로 허용되지 않는 문자가 들어간 속성 이름을 사용해야 하는 경우에는 반드시 `}<strong parentName="p">{`대괄호 표기법`}</strong>{`을 사용해야 합니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 대괄호 표기법 (Bracket notation)
person['한국 나이'] = 20;
`}</code></pre>
    <p>{`위와 같은 경우가 아니라면, `}<strong parentName="p">{`주로 점 표기법이 많이 사용되는 편입니다`}</strong>{`. 이 교재에서도 특별한 이유가 없는 한 점 표기법을 사용하겠습니다.`}</p>
    <h2>{`객체 다루기`}</h2>
    <p>{`속성 접근자, `}<inlineCode parentName="p">{`delete`}</inlineCode>{` 연산자, `}<inlineCode parentName="p">{`in`}</inlineCode>{` 연산자 등을 이용해서 객체에 대한 정보를 읽고 쓸 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const person = {
  name: '윤아준',
  age: 19,
  languages: ['Korean', 'English']
};

// 속성 읽기
person.name; // '윤아준'
person.age; // 19
person.languages[0] // 'Korean'

// 속성 쓰기
person.name = '신하경';
person.age = 20;

// 새 속성 추가하기
person.address = '서울특별시 강남구 신사동';

// 속성 삭제하기
delete person.address;

// 속성이 객체에 존재하는지 확인하기
'name' in person; // true
'phoneNumber' in person; // false
`}</code></pre>
    <h2>{`메소드 (Method)`}</h2>
    <p>{`객체의 속성값으로 `}<strong parentName="p">{`함수`}</strong>{`를 지정할 수도 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const person = {
  greet: function() {
    return 'hello';
  }
};

person.greet(); // 'hello';
`}</code></pre>
    <p>{`위와 같이 `}<strong parentName="p">{`어떤 객체의 속성으로 접근해서 사용하는 함수`}</strong>{`를 `}<strong parentName="p">{`메소드(method)`}</strong>{`라고 부릅니다. 아래와 같이, 객체 리터럴 안에서 특별한 표기법을 사용해 메소드를 정의할 수도 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 위 예제와 완전히 똑같이 동작합니다.
const person = {
  greet() {
    return 'hello';
  }
};

person.greet(); // 'hello';
`}</code></pre>
    <p>{`메소드를 지정하기 위해 조금 더 효율적인 방법을 사용할 수도 있는데, 아래 `}<strong parentName="p">{`프로토타입`}</strong>{` 섹션에서 자세히 다룹니다.`}</p>
    <h2>{`this`}</h2>
    <p>{`다른 함수들과 달리 '메소드'라는 특별한 이름을 사용하는 이유는, 메소드가 다른 함수들과는 다르게 특별히 취급되기 때문입니다. `}<inlineCode parentName="p">{`this`}</inlineCode>{` 키워드를 사용하면, 메소드 호출 시에 해당 메소드를 갖고 있는 객체에 접근할 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const person = {
  name: '윤아준',
  age: 19,
  introduce() {
    // \`this\`를 사용해서 객체의 속성에 접근함
    return \`안녕하세요, 제 이름은 \${this.name}입니다. 제 나이는 \${this.age}살 입니다.\`
  },
  getOlder() {
    // \`this\`를 사용해서 객체의 속성을 갱신함
    this.age++;
  }
};

person.introduce(); // '안녕하세요, 제 이름은 윤아준입니다. 제 나이는 19살 입니다.'
person.getOlder(); // undefined
person.introduce(); // '안녕하세요, 제 이름은 윤아준입니다. 제 나이는 20살 입니다.'
`}</code></pre>
    <p>{`메소드를 사용하면, `}<strong parentName="p">{`데이터`}</strong>{`와, 그 데이터와 관련된 `}<strong parentName="p">{`동작`}</strong>{`을 `}<strong parentName="p">{`객체라는 하나의 단위로 묶어서 다룰 수 있습니다.`}</strong>{` 이것이 함수 대신 메소드를 사용하는 핵심적인 이유입니다.`}</p>
    <p>{`여기서 주의할 점이 있습니다. `}<inlineCode parentName="p">{`function`}</inlineCode>{` 키워드를 통해 정의된 함수 내부의 `}<inlineCode parentName="p">{`this`}</inlineCode>{` 키워드가 `}<strong parentName="p">{`실제로 무엇을 가리킬 것인가`}</strong>{`는, 메소드가 어떻게 `}<strong parentName="p">{`정의`}</strong>{`되는가에 의해 결정되는 것이 아니라 메소드가 어떻게 `}<strong parentName="p">{`사용`}</strong>{`되는가에 의해 결정됩니다. 예를 들어 보겠습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function introduce() {
  return \`안녕하세요, 제 이름은 \${this.name}입니다.\`;
}

const person1 = {
  name: '윤아준',
  introduce
};

const person2 = {
  name: '신하경',
  introduce
};

person1.introduce(); // 안녕하세요, 제 이름은 운아준입니다.
person2.introduce(); // 안녕하세요, 제 이름은 신하경입니다.
`}</code></pre>
    <p>{`이렇게 `}<inlineCode parentName="p">{`introduce`}</inlineCode>{`라는 함수가 객체 외부에서 정의되었고, `}<inlineCode parentName="p">{`person1`}</inlineCode>{`과 `}<inlineCode parentName="p">{`person2`}</inlineCode>{`에서 재사용되었는데도 불구하고 메소드가 잘 동작했습니다. 즉, 같은 함수임에도 불구하고 `}<strong parentName="p">{`어떤 객체의 메소드로 사용되느냐에 따라 메소드 내부의 `}<inlineCode parentName="strong">{`this`}</inlineCode>{`가 가리키는 객체가 달라질 수 있다`}</strong>{`는 것입니다.`}</p>
    <p>{`다만, `}<strong parentName="p">{`화살표 함수`}</strong>{`는 `}<inlineCode parentName="p">{`this`}</inlineCode>{` 키워드를 전혀 다르게 취급하기 때문에 위와 같은 방식으로는 메소드로 사용될 수 없습니다. 또한, `}<inlineCode parentName="p">{`function`}</inlineCode>{` 키워드를 통해 정의된 메소드가 항상 위와 같은 방식으로 `}<inlineCode parentName="p">{`this`}</inlineCode>{`를 취급하는 것은 아닙니다. 특별한 방법을 통해 아예 `}<inlineCode parentName="p">{`this`}</inlineCode>{`를 우리가 원하는 객체로 바꿔버릴 수도 있습니다. 이에 대해서는 `}<a parentName="p" {...{
        "href": "./230-function-in-depth.md"
      }}>{`함수 더 알아보기`}</a>{` 챕터에서 자세히 알아보겠습니다.`}</p>
    <h2>{`프로토타입 (Prototype)`}</h2>
    <p>{`우리가 쓰는 대부분의 프로그램들은 아주 많은 수의 비슷한 객체를 만들어냅니다.`}</p>
    <ul>
      <li parentName="ul">{`스프레트시트의 `}<strong parentName="li">{`셀`}</strong></li>
      <li parentName="ul">{`슈팅 게임에서의 `}<strong parentName="li">{`총알`}</strong></li>
      <li parentName="ul">{`DOM API의 `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement"
          }}>{`HTMLElement`}</a></strong></li>
    </ul>
    <p>{`이 객체들은 아마도 `}<strong parentName="p">{`각각 다른 속성`}</strong>{`을 가지고 있을 것입니다.`}</p>
    <ul>
      <li parentName="ul">{`셀 안에 들어있는 `}<strong parentName="li">{`데이터`}</strong></li>
      <li parentName="ul">{`총알의 `}<strong parentName="li">{`현재 위치`}</strong></li>
      <li parentName="ul">{`HTMLElement의 `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/style"
          }}>{`인라인 스타일`}</a></strong></li>
    </ul>
    <p>{`그렇지만, 그 수가 아무리 많더라도 `}<strong parentName="p">{`공통으로 사용하는 속성과 메소드`}</strong>{`들이 있을 것입니다.`}</p>
    <ul>
      <li parentName="ul">{`셀의 내용을 `}<strong parentName="li">{`편집하는 메소드`}</strong></li>
      <li parentName="ul">{`총알의 `}<strong parentName="li">{`모양`}</strong></li>
      <li parentName="ul">{`특정 HTMLElement에 키보드 포커스를 맞추는 메소드인 `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/focus"
          }}>{`focus`}</a></strong></li>
    </ul>
    <p>{`위와 같이 수많은 객체가 공통으로 사용하는 속성과 메소드를 `}<strong parentName="p">{`중복해서 저장`}</strong>{`하는 것은 컴퓨터의 아까운 저장 공간을 낭비하는 일일 것입니다. 예를 들어, 아래와 같이 객체를 생성하면 모든 객체에 똑같은 `}<inlineCode parentName="p">{`introduce`}</inlineCode>{` 메소드가 저장되어 객체 1000개마다 별개의 함수, 즉 총 1000개의 함수가 생성됩니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 사람을 나타내는 객체를 생성하는 팩토리 함수
function personFactory(name) {
  return {
    name,
    introduce: function() {
      return \`안녕하세요, 제 이름은 \${this.name}입니다.\`;
    }
  };
}

const people = [];

for (let i = 0; i < 1000; i++) {
  people.push(personFactory('윤아준'))
}

people[0].introduce === people[1].introduce // false
`}</code></pre>
    <p>{`JavaScript에서는 이렇게 객체 간에 공유되어야 하는 속성과 메소드를, 프로토타입(prototype)이라는 기능을 이용해서 효율적으로 저장할 수 있습니다. 어떤 객체에 프로토타입을 지정하면, 프로토타입의 속성을 해당 객체에서 `}<strong parentName="p">{`재사용`}</strong>{`할 수 있습니다. 객체의 프로토타입을 지정하는 방법에는 여러 가지가 있는데, 가장 쉬운 방법은 `}<inlineCode parentName="p">{`Object.create`}</inlineCode>{` 함수를 이용하는 것입니다.`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const personPrototype = {
  introduce: function() {
    return \`안녕하세요, 제 이름은 \${this.name}입니다.\`;
  }
};

const person1 = Object.create(personPrototype); // 새 객체를 생성하고 프로토타입을 지정함
person1.name = '윤아준';

const person2 = Object.create(personPrototype);
person2.name = '신하경';

person1.introduce(); // 안녕하세요, 제 이름은 윤아준입니다.
person2.introduce(); // 안녕하세요, 제 이름은 신하경입니다.

person1.introduce === person2.introduce; // true
`}</code></pre>
    <p>{`이렇게 프로토타입 기능을 이용해 한 객체에서 다른 객체의 기능을 가져와 사용하는 것을 `}<strong parentName="p">{`프로토타입 상속(prototype inheritance)`}</strong>{`이라고 합니다. 위와 같은 경우는 `}<strong parentName="p">{`"`}<inlineCode parentName="strong">{`personPrototype`}</inlineCode>{`은 `}<inlineCode parentName="strong">{`person1`}</inlineCode>{`의 프로토타입이다."`}</strong>{`, `}<strong parentName="p">{`"`}<inlineCode parentName="strong">{`person1`}</inlineCode>{` 객체는 `}<inlineCode parentName="strong">{`personPrototype`}</inlineCode>{` 객체를 상속받았다"`}</strong>{`고 표현합니다. 프로토타입 상속은 다른 언어에서는 흔히 찾아볼 수 없는 JavaScript의 특징적인 기능입니다.`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup></p>
    <h3>{`프로토타입 읽고 쓰기`}<sup parentName="h3" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup></h3>
    <p>{`어떤 객체의 프로토타입을 읽어오기 위해 `}<inlineCode parentName="p">{`Object.getPrototypeOf`}</inlineCode>{` 함수를 사용할 수 있습니다. 또한 `}<inlineCode parentName="p">{`Object.setPrototypeOf`}</inlineCode>{` 함수를 통해 이미 생성된 객체의 프로토타입을 변경할 수 있습니다. 하지만 객체가 생성된 이후에 프로토타입을 변경하는 작업은 굉장히 느리므로 `}<inlineCode parentName="p">{`Object.setPrototypeOf`}</inlineCode>{` 함수의 사용은 피하는 것이 좋습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const parent = {
  familyName: '윤'
};
const child = Object.create(parent);

Object.getPrototypeOf(child) === parent; // true

const newParent = {
  familyName: '신'
};
Object.setPrototypeOf(child, newParent);
Object.getPrototypeOf(child) === parent; // false
`}</code></pre>
    <p>{`객체 리터럴을 통해 생성된 객체의 프로토타입에는 자동으로 `}<inlineCode parentName="p">{`Object.prototype`}</inlineCode>{`이 지정됩니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const obj = {};
Object.getPrototypeOf(obj) === Object.prototype; // true
`}</code></pre>
    <h3>{`프로토타입 체인 (Prototype Chain)`}</h3>
    <p>{`프로토타입 상속을 받은 객체가 실제로 어떻게 생겼는지를 확인해보겠습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const parent = {
  a: 1
};
const child = {
  b: 2
};
Object.setPrototypeOf(child, parent);
console.log(child); // { 'b': 2 }
`}</code></pre>
    <p>{`그러니까 `}<inlineCode parentName="p">{`child`}</inlineCode>{` 객체에는 `}<inlineCode parentName="p">{`a`}</inlineCode>{` 속성이 없습니다! 그런데 `}<inlineCode parentName="p">{`child`}</inlineCode>{` 객체의 `}<inlineCode parentName="p">{`a`}</inlineCode>{` 속성을 출력해보면, 아래와 같은 결과가 나옵니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`console.log(child.a); // 1
`}</code></pre>
    <p>{`도대체 어떻게 된 일일까요? 사실, `}<inlineCode parentName="p">{`child.a`}</inlineCode>{`과 같이 JavaScript 객체의 속성에 접근하면, JavaScript 엔진은 `}<inlineCode parentName="p">{`child`}</inlineCode>{` 객체의 속성만 확인하는 것이 아니라 `}<strong parentName="p">{`프로토타입 객체의 속성까지 확인`}</strong>{`합니다. 그래서 프로토타입에 해당 이름을 갖는 속성이 있다면 그 속성의 값을 반환합니다.`}</p>
    <p>{`만약에 프로토타입 객체에도 해당 이름의 속성이 없으면 어떻게 될까요? 여기서 짚고 넘어가야 할 것은 `}<strong parentName="p">{`프로토타입 객체도 객체`}</strong>{`라는 것입니다. 즉, `}<strong parentName="p">{`프로토타입 객체의 프로토타입 객체`}</strong>{`가 있을 수 있다는 말이죠. 이렇게 계속 이어져 있는 프로토타입의 연쇄를 프로토타입 체인(prototype chain)이라 부릅니다.`}</p>
    <p>{`위의 예제에서, 만약에 `}<inlineCode parentName="p">{`child`}</inlineCode>{` 객체의 프로토타입에도 `}<inlineCode parentName="p">{`a`}</inlineCode>{` 속성이 없다면 JavaScript 엔진은 프로토타입의 프로토타입까지 확인합니다. 여기서도 발견하지 못하면 프로토타입의 프로토타입의 프로토타입... 이렇게 `}<strong parentName="p">{`더 이상 남아있는 프로토타입이 없을 때까지 확인`}</strong>{`해보고, 그래도 찾지 못하면 그때서야 속성값으로 `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`를 반환합니다. 즉, JavaScript 엔진은 `}<strong parentName="p">{`속성 접근자를 통해 어떤 객체의 속성을 확인할 때 프로토타입 체인을 전부 확인`}</strong>{`합니다. 예를 들어 보겠습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const obj1 = {
  a: 1
};

const obj2 = {
  b: 2
};

const obj3 = {
  c: 3
};

// \`obj3 -> obj2 -> obj1\` 과 같이 상속
Object.setPrototypeOf(obj2, obj1);
Object.setPrototypeOf(obj3, obj2);

console.log(obj3.a); // \`obj3\`의 프로토타입의 프로토타입에 존재하는 속성 \`a\`의 값을 출력
console.log(obj3.b); // \`obj3\`의 프로토타입에 존재하는 속성 \`b\`의 값을 출력
console.log(obj3.c); // \`obj3\`에 존재하는 속성 \`c\`의 값을 출력
`}</code></pre>
    <p>{`프로토타입 체인은 눈에 명확히 보이지는 않지만, 객체의 속성에 접근할 때마다 탐색됩니다. 따라서 프로토타입 체인의 깊이가 너무 깊으면 속성의 읽기 속도에 영향을 미치므로 주의해야 합니다.`}</p>
    <p>{`어떤 객체가 다른 객체의 프로토타입 체인에 존재하는지 확인하기 위해서 `}<inlineCode parentName="p">{`Object.prototype.isPrototypeOf`}</inlineCode>{` 메소드를 사용할 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`obj1.isPrototypeOf(obj3); // true
obj2.isPrototypeOf(obj3); // true
`}</code></pre>
    <h3>{`프로토타입 체인의 끝`}</h3>
    <p>{`위의 설명에서 `}<strong parentName="p">{`'속성에 접근할 때 더이상 프로토타입이 없을 때까지 프로토타입 체인을 확인한다'`}</strong>{`고 했는데, 프로토타입이 더 이상 없다는 게 무슨 뜻일까요?`}</p>
    <p>{`JavaScript에서는 객체의 프로토타입으로 `}<strong parentName="p">{`객체 또는 null`}</strong>{` 이외의 값을 지정할 수 없습니다. 지정하려고 하면 에러가 나거나, 무시됩니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Object.create(1); // Uncaught TypeError: Object prototype may only be an Object or null
`}</code></pre>
    <p>{`그리고 `}<inlineCode parentName="p">{`Object.prototype`}</inlineCode>{`의 프로토타입을 확인해보면 `}<inlineCode parentName="p">{`null`}</inlineCode>{`이 나옵니다!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Object.getPrototypeOf(Object.prototype); // null
`}</code></pre>
    <p>{`위의 사실들을 종합해보면, 프로토타입 체인을 따라가다 보면 언젠가는 `}<inlineCode parentName="p">{`null`}</inlineCode>{`을 만난다는 결론에 도달하게 됩니다.`}<sup parentName="p" {...{
        "id": "fnref-6"
      }}><a parentName="sup" {...{
          "href": "#fn-6",
          "className": "footnote-ref"
        }}>{`6`}</a></sup>{` 프로토타입을 명시적으로 `}<inlineCode parentName="p">{`null`}</inlineCode>{`로 지정하지 않아도, 언젠가는 `}<inlineCode parentName="p">{`Object.prototype`}</inlineCode>{`, 즉 프로토타입이 `}<inlineCode parentName="p">{`null`}</inlineCode>{`인 객체를 만나게 됩니다. 이 때에 프로토타입 체인을 확인하는 과정이 끝나는 것입니다.`}</p>
    <h3>{`속성 가리기 (Property Shadowing)`}</h3>
    <p>{`만약 프로토타입 체인에서 같은 이름의 속성이 여러 번 등장하면 어떤 일이 일어날까요? 아래 예제를 통해 확인해봅시다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const parent = {
  prop: 1
};

const child = {
  prop: 2
};

Object.setPrototypeOf(child, parent); // \`child\`의 프로토타입을 \`parent\`로 재설정합니다.

child.prop; // 2
`}</code></pre>
    <p>{`위와 같이 `}<inlineCode parentName="p">{`parent`}</inlineCode>{`에 같은 이름의 속성이 있음에도 불구하고, `}<inlineCode parentName="p">{`child.prop`}</inlineCode>{`에 접근하면 프로토타입 체인에서 가장 먼저 만나는 값이 불러와집니다. 이렇게 프로토타입 체인의 상위에 있는 속성이 하위 속성에 의해 가려지는 현상을 `}<strong parentName="p">{`속성 가리기(property shadowing)`}</strong>{`라고 합니다.`}</p>
    <h3>{`프로토타입을 간접적으로 변경하는 것은 불가능`}</h3>
    <p>{`그렇다면 `}<inlineCode parentName="p">{`child`}</inlineCode>{`를 통해 `}<inlineCode parentName="p">{`parent`}</inlineCode>{`의 속성을 변경하거나 삭제할 수 있을까요?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const parent = {
  prop: '😝'
};

const child = Object.create(parent);

// 프로토타입 객체의 속성을 간접적으로 삭제하는 것은 불가능합니다.
delete child.prop;
parent.prop; // '😝'

// 프로토타입 객체의 속성을 간접적으로 변경하는 것은 불가능합니다.
child.prop = '💀';
parent.prop; // '😝'
child.prop; // '💀'
`}</code></pre>
    <p>{`위와 같이, 어떤 객체의 속성을 변경하거나 속성을 삭제하는 작업은 그 객체의 `}<strong parentName="p">{`프로토타입에 아무런 영향을 미치지 않습니다.`}</strong></p>
    <h2>{`생성자 (Constructor)`}</h2>
    <p>{`이제까지는 객체를 생성하기 위해 객체 리터럴 또는 `}<inlineCode parentName="p">{`Object.create`}</inlineCode>{` 함수를 사용했습니다. 하지만 이것 말고도 한 가지 방법이 더 있는데, 바로 `}<inlineCode parentName="p">{`new`}</inlineCode>{` 키워드를 이용하는 것입니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const obj = new Object();
`}</code></pre>
    <p>{`위 문장은 `}<inlineCode parentName="p">{`new`}</inlineCode>{` 키워드가 붙었다는 것 말고는 `}<strong parentName="p">{`함수 호출`}</strong>{` 문법과 비슷하게 생겼는데, 사실...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`typeof Object; // 'function'
`}</code></pre>
    <p><inlineCode parentName="p">{`Object`}</inlineCode>{`는 함수입니다! 이렇게 객체를 만들 때 `}<inlineCode parentName="p">{`new`}</inlineCode>{` 키워드와 함께 사용하는 함수를 가지고 생성자(constructor)라고 부릅니다.`}</p>
    <h3>{`생성자 정의하기`}</h3>
    <p>{`JavaScript에서는 `}<inlineCode parentName="p">{`Object`}</inlineCode>{` 뿐만 아니라, 내장된 많은 생성자들이 있고, 심지어 프로그래머가 직접 생성자를 만들 수도 있습니다. 여기서 `}<inlineCode parentName="p">{`this`}</inlineCode>{` 키워드가 한 번 더 등장합니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 생성자 정의
function Person(name) {
  this.name = name;
}

// 생성자를 통한 객체 생성
const person1 = new Person('윤아준');
`}</code></pre>
    <p>{`위에서 `}<inlineCode parentName="p">{`function`}</inlineCode>{` 구문을 통해 `}<inlineCode parentName="p">{`Person`}</inlineCode>{`이라는 생성자를 정의하고, 생성자 안에서는 `}<inlineCode parentName="p">{`this`}</inlineCode>{` 키워드를 사용해서 `}<strong parentName="p">{`새로 만들어질 객체의 속성을 지정`}</strong>{`해 주었습니다. `}<inlineCode parentName="p">{`new`}</inlineCode>{` 키워드를 사용해서 객체를 생성하는 순간에 생성자 안에 있는 코드가 실행되어 객체의 속성이 지정되는 것입니다.`}</p>
    <p><strong parentName="p">{`생성자의 이름`}</strong>{`으로는 식별자로 사용할 수 있는 것이면 뭐든지 사용할 수 있지만, 변수와는 다르게 `}<strong parentName="p">{`대문자로 시작`}</strong>{`하게끔 짓는 것이 널리 사용되는 관례입니다.`}</p>
    <h3>{`인스턴스 (Instance)`}</h3>
    <p>{`생성자를 통해 생성된 객체를 그 생성자의 `}<strong parentName="p">{`인스턴스(instance)`}</strong>{`라고 합니다. 위의 예제에서는 `}<inlineCode parentName="p">{`person1`}</inlineCode>{`이 `}<inlineCode parentName="p">{`Person`}</inlineCode>{`의 인스턴스입니다. `}<inlineCode parentName="p">{`instanceof`}</inlineCode>{` 연산자를 사용하면, 객체가 특정 생성자의 인스턴스가 맞는지를 확인할 수 있습니다.`}<sup parentName="p" {...{
        "id": "fnref-7"
      }}><a parentName="sup" {...{
          "href": "#fn-7",
          "className": "footnote-ref"
        }}>{`7`}</a></sup></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`person1 instanceof Person; // true
`}</code></pre>
    <p>{`객체 리터럴을 통해 생성된 객체는 `}<inlineCode parentName="p">{`Object`}</inlineCode>{`의 인스턴스입니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const obj = {};
obj instanceof Object; // true
`}</code></pre>
    <h3>{`생성자와 프로토타입`}</h3>
    <p>{`생성자와 관련해서 알아야 할 것이 더 있습니다. 바로 생성자의 `}<inlineCode parentName="p">{`prototype`}</inlineCode>{` 속성입니다. 생성자를 통해 만들어낸 객체의 `}<strong parentName="p">{`프로토타입`}</strong>{`에는 `}<strong parentName="p">{`생성자의 `}<inlineCode parentName="strong">{`prototype`}</inlineCode>{` 속성에 저장되어 있는 객체`}</strong>{`가 `}<strong parentName="p">{`자동으로 지정됩니다.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Object.getPrototypeOf(person1) === Person.prototype; // true
`}</code></pre>
    <p>{`그런데 좀 이상한 점이 있습니다. 우리는 `}<inlineCode parentName="p">{`Person.prototype`}</inlineCode>{`에 객체를 저장한 적이 없습니다. 심지어 `}<inlineCode parentName="p">{`Person`}</inlineCode>{`은 함수인데도 불구하고 속성을 갖고 있습니다! 어떻게 된 일일까요?`}</p>
    <p>{`먼저, JavaScript에서는 `}<strong parentName="p">{`함수도 특별한 형태의 객체입니다.`}</strong>{` 이에 대해서는 `}<a parentName="p" {...{
        "href": "./230-function-in-depth.md"
      }}>{`함수 더 알아보기`}</a>{` 챕터에서 자세히 다룹니다.`}</p>
    <p>{`그리고, JavaScript에서는 `}<inlineCode parentName="p">{`function`}</inlineCode>{` 구문을 통해 함수를 정의할 때 `}<strong parentName="p">{`함수의 `}<inlineCode parentName="strong">{`prototype`}</inlineCode>{` 속성에 객체가 자동으로 생성되어 저장됩니다.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Person() {
  // ...
}
typeof Person.prototype; // 'object'
`}</code></pre>
    <h3>{`constructor`}</h3>
    <p>{`생성자의 `}<inlineCode parentName="p">{`prototype`}</inlineCode>{` 속성에 자동 생성되는 객체에는 `}<inlineCode parentName="p">{`constructor`}</inlineCode>{`라는 특별한 속성이 들어있습니다. 이 속성에는 생성자 자신이 저장됩니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Person() {
  // ...
}
Person.prototype.constructor === Person; // true
`}</code></pre>
    <p>{`이를 통해, 어떤 객체가 어떤 생성자로부터 생성되었는지를 `}<inlineCode parentName="p">{`constructor`}</inlineCode>{` 속성을 통해 알아낼 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Person() {
  // ...
}
const person = new Person();
person.constructor === Person;
`}</code></pre>
    <h3>{`팩토리 함수의 재작성`}</h3>
    <p>{`수고하셨습니다! 이제 생성자를 사용할 준비를 마쳤습니다. 생성자를 이용해서 상단의 `}<inlineCode parentName="p">{`personFactory`}</inlineCode>{` 함수를 다시 작성해보겠습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 사람을 나타내는 객체를 생성하는 팩토리 함수
function Person(name) {
  this.name = name;
}
Person.prototype.introduce = function() {
  return \`안녕하세요, 제 이름은 \${this.name}입니다.\`;
};

const person = new Person('윤아준');

person.introduce(); // '안녕하세요, 제 이름은 윤아준입니다.'
`}</code></pre>
    <h2>{`정적 메소드 (Static Method)`}</h2>
    <p>{`JavaScript의 함수는 객체이기도 하다는 사실을 앞에서 언급했습니다. 생성자의 속성에 직접 지정된 메소드를 가지고 정적 메소드(static method)라고 합니다. 우리가 이제까지 유용하게 사용했던 `}<inlineCode parentName="p">{`Number.isNaN`}</inlineCode>{`, `}<inlineCode parentName="p">{`Object.getPropertyOf`}</inlineCode>{` 등의 함수들은 모두 정적 메소드입니다. 정적 메소드는 특정 인스턴스에 대한 작업이 아니라, 해당 생성자와 관련된 일반적인 작업을 정의하고 싶을 때 사용됩니다.`}</p>
    <p>{`다음과 같이 정적 메소드를 정의할 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// 생성자의 속성에 함수를 직접 할당합니다.
Person.compareAge = function(person1, person2) {
  if (person1.age < person2.age) {
    return '첫 번째 사람의 나이가 더 많습니다.';
  } else if (person1.age === person2.age) {
    return '두 사람의 나이가 같습니다.';
  } else {
    return '두 번째 사람의 나이가 더 많습니다.';
  }
}
`}</code></pre>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`다른 언어에서 HashMap, Dictionary 등으로 불리는 자료 구조와 유사하다고 할 수 있습니다.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`한글은 JavaScript의 식별자로 사용할 수 있습니다만, 권장되지는 않습니다.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}><inlineCode parentName="li">{`Object.create`}</inlineCode>{` 함수에는 프로토타입을 지정하는 것 말고도 다른 기능이 있는데, 이에 대해서는 `}<a parentName="li" {...{
            "href": "./240-object-in-depth.md"
          }}>{`객체 더 알아보기`}</a>{` 챕터에서 자세히 다룹니다.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`Common Lisp, Self 등의 몇몇 Lisp 방언과 Lua 등이 프로토타입 상속을 내장하고 있지만, 대부분의 유명한 범용 프로그래밍 언어는 프로토타입 상속과 같은 기능을 내장하고 있지 않습니다.`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}>{`객체의 `}<inlineCode parentName="li">{`__proto__`}</inlineCode>{` 속성을 통해서도 프로토타입을 조작할 수 있지만, 이 기능은 `}<inlineCode parentName="li">{`Object.getPrototypeOf`}</inlineCode>{`, `}<inlineCode parentName="li">{`Object.setPrototypeOf`}</inlineCode>{`에 의해 대체되었습니다.`}<a parentName="li" {...{
            "href": "#fnref-5",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-6"
        }}>{`'a의 프로토타입을 b로, b의 프로토타입을 a로 지정'하면 어떻게 될지 궁금하다고요? 최신 JavaScript 엔진에서는 순환하는 프로토타입 체인을 만들 수 없도록 제한하고 있습니다. 한 번 시험해보세요: `}<inlineCode parentName="li">{`a = {}; b = {}; Object.setPrototypeOf(a, b); Object.setPrototypeOf(b, a);`}</inlineCode><a parentName="li" {...{
            "href": "#fnref-6",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-7"
        }}><inlineCode parentName="li">{`instanceof`}</inlineCode>{` 연산자는 `}<strong parentName="li">{`생성자의 `}<inlineCode parentName="strong">{`prototype`}</inlineCode>{` 속성`}</strong>{`이 `}<strong parentName="li">{`객체의 프로토타입 체인에 등장하는지`}</strong>{`를 검사합니다. 그래서, 특별한 경우가 아니라면 생성자를 통해 생성된 객체는 `}<inlineCode parentName="li">{`Object`}</inlineCode>{` 생성자의 인스턴스이기도 합니다.`}<a parentName="li" {...{
            "href": "#fnref-7",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      